@font-face { font-family: Siemens; src: url(/static/media/SiemensSans_Prof_Roman.adf59734.otf); } 
@font-face { font-family: Siemens; font-weight: bold; src: url(/static/media/SiemensSans_Prof_Bold.2ca0572d.otf);}

body {
  margin: 0;
  font-family: Siemens, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Siemens, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
}

.App-logo-animation {
  -webkit-animation: App-logo-spin infinite 10s linear;
          animation: App-logo-spin infinite 10s linear;
}
.root{
  height: 100vh;
}
.App {
  background-color: rgb(0,0,40);
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
  align-content:space-around;
}


.spin{
  position: fixed;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  top: 50%;
  left: 50%;
}

#logoutBtn{
  position: absolute;
  right: 5px;
  top: 5px;
  width: 100px ;
}

.demokofferContainer{
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 10px;
  width: 100%;  
  height: 85vh;
}
.gridelement{
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  grid-row-gap: 5px; 
  row-gap: 5px;
}
.demokoffer { grid-area: 1 / 2 / 5 / 3; display: flex; justify-content: center; align-items: center; flex-direction: column;}
.airqual { grid-area: 1 / 1 / 2 / 2; border-bottom: 1px solid rgb(0, 197, 197);}
.humidity { grid-area: 2 / 1 / 3 / 2; border-bottom: 1px solid rgb(0, 197, 197);}
.temp { grid-area: 1 / 3 / 2 / 4; border-bottom: 1px solid rgb(0, 197, 197);}
.settemp { grid-area: 2 / 3 / 3 / 4; border-bottom: 1px solid rgb(0, 197, 197);}
.window { grid-area:  3 / 1 / 4 / 2; }
.presence { grid-area: 3 / 3 / 4 / 4; }

.SiemensBtn{
  background-color: rgb(0, 197, 197);
  width: 200px;
  height: 40px;
  font-family: Siemens;
  font-weight: 700;
  font-size: large;
  border:none;
  transition: 0.6s;
  cursor: pointer;
  margin-left: 5px; 
  border-radius: 0px;
  color:rgb(0, 0, 40)
}
.SiemensBtn:hover{
  background-color: rgb(0, 255, 189);
  transition: background-color 0.2s ease-in-out 0s;
}
.valueLabel{
  font-weight: bold;
  font-size: xx-large;
}
.valueBox{
  font-size: xx-large;
}
.valueTimestamp{
  font-size: small;
}

.spinInformation{
  border-radius: 95%;
  width: 75px;
  height: 75px;
  position: fixed;
  left: 0;
  top:0;
  background-color: aliceblue;
  color: black;
  font-size: x-small;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.title{
  border-image: linear-gradient(90deg, rgb(0, 197, 197), rgb(0, 255, 189)) 10% 30%;
  border-width: 3px;
  border-style: solid;
  border-right: none;
  border-top: none;
  border-left: none;

  font-family: Siemens;
  font-size: xx-large;
  font-weight: bold;
}

.Timestamp{
  font-size: medium;
  width: 100%;
}

.Timestamp span{
  font-size: small;
}

.InteractionButtons{
  width: 100%;
}

#loginBtn{
  margin-top: 10px;
}

.tempButton{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: rgb(0, 197, 197);
  display: none;
}

.settemp .valueBox{
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;

}
.settemp .valueBox div{
  padding-left: 10px;
  padding-right: 10px;
}

text{
  fill:white;
}
.buttonIcons{
  height: 25px;
  width: 25px;
}
.serviceButtons{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 50px;
  border-radius: 50%;
  position: absolute;
}
#showServices{
  right: 0px;
}
#dataRetrieval{
  left:0px;
}
.group{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.group .form-input-label{
  width: 50%;
}

.card form .group{
  margin-top: 20px;
}
.card form input{
  width: 50%;
}

.card form .button-group{
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

@media only screen and (max-width: 900px){
  .title span{
    display: block;
  }
  .demokoffer{
    display: flex; align-items: center;
  }
  .App{
    justify-content: flex-start;
  }
 /* #main{
  width: 70% !important;
 } */
  .parent{
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(7, 1fr);
    height:auto;
  }
  .gridelement{
    border-bottom: 1px solid rgb(0, 197, 197);
  }
  .demokofferButtons{
    width: 100%;
  }
  .demokoffer   { grid-area: 1 / 1; display: flex; justify-content: center; align-items: center; flex-direction: column;}
  .airqual      { grid-area: 2 / 1;}
  .humidity     { grid-area: 3 / 1;}
  .temp         { grid-area: 4 / 1;}
  .settemp      { grid-area: 5 / 1;}
  .window       { grid-area: 6 / 1;}
  .presence     { grid-area: 7 / 1;}
  
  .title{
    height: 10vh;
    width: 100%;
    font-size: x-large;
    margin-bottom: 5px;
  }

  #logoutBtn{
    height: 25px;
    width: 50px !important;
    font-size: x-small;
  }

  .valueLabel{
    font-size: medium !important;
  }
  .valueBox{
    font-size: medium !important;
  }
  .valueTimestamp{
    font-size: x-small !important;
  }
  
  .demokofferContainer{
    justify-content: flex-start !important;
    height: inherit;
  }
  .settemp .valueBox{
    justify-content: space-evenly !important;
  }
  .settemp .valueBox div{
    padding: 0px !important;
  }

  form .button-group .SiemensBtn{
    width: 70px;
  }
  .presenceIcon{
    width: 60px;
    height: 60px;
  }
  .windowIcon{
    width: 60px;
    height: 60px;
  }
  .tempButton{
    width: 20px !important;
    height: 20px !important;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.healthOverlay{
    position: absolute;
    left: 5px;
    top: 5px;
    bottom: 5px;
    background-color: rgba(243,243,243,0.9) ;
    color: rgb(0,0,40);
    border: 1px solid rgb(200, 200, 200);
    padding: 5px;
    z-index: 2;
}
.healthOverlay .Timestamp{
    margin-top: 10px;
}
th{
    font-size: large;
    font-weight: bolder;
    padding-left: 3px;
}
.service{
    font-size: small;
}
td{
    padding: 5px;
    font-size: large;
    
}
tr{
    border: 2px solid rgb(0,215,160);
}
table{
    border-collapse: collapse; 
    width: 100%;
    text-align: left;
}
.closeButton{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    background-color: rgb(0, 197, 197);
    font-size: small;
    line-height: 20px;
    cursor: default;
    text-align: center;
}
.closeButton:hover{
    background-color: rgb(0, 255, 189);
    transition: background-color 0.2s ease-in-out 0s;
  }
.healthIndicator{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.statusCell{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
