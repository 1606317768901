.healthOverlay{
    position: absolute;
    left: 5px;
    top: 5px;
    bottom: 5px;
    background-color: rgba(243,243,243,0.9) ;
    color: rgb(0,0,40);
    border: 1px solid rgb(200, 200, 200);
    padding: 5px;
    z-index: 2;
}
.healthOverlay .Timestamp{
    margin-top: 10px;
}
th{
    font-size: large;
    font-weight: bolder;
    padding-left: 3px;
}
.service{
    font-size: small;
}
td{
    padding: 5px;
    font-size: large;
    
}
tr{
    border: 2px solid rgb(0,215,160);
}
table{
    border-collapse: collapse; 
    width: 100%;
    text-align: left;
}
.closeButton{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    background-color: rgb(0, 197, 197);
    font-size: small;
    line-height: 20px;
    cursor: default;
    text-align: center;
}
.closeButton:hover{
    background-color: rgb(0, 255, 189);
    transition: background-color 0.2s ease-in-out 0s;
  }
.healthIndicator{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.statusCell{
    display: flex;
    justify-content: space-between;
    align-items: center;
}